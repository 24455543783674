<template>
    <div class="">
 
        <!-- Content Header (Page header) -->
        <div class="">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6 ">
                            <h1 class="m-0 text-dark">Admin Dashboard</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                        </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid  pb-4">
                    <!-- Small boxes (Stat box) -->
                        <stat-box />
                    <!-- /.row -->
                    <!-- Main row -->
                    <div class="row" v-if="subdomain == 'waterski'">
                        <section class="col-lg-7 connectedSortable">
                            <active-members-graph />
                            <!-- Custom tabs (Charts with tabs)-->
                            <div class="card">
                                <div class="card-header bg-white">
                                    <h3 class="card-title">
                                    <i class="fas fa-chart-pie mr-1"></i>
                                        Registrations By Category
                                    </h3>
                                </div><!-- /.card-header -->
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <members-metrics title="Age" apiUrl="members/age"/>
                                        </div>
                                        <div class="col-lg-6">
                                            <members-metrics title="Gender" apiUrl="members/gender"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <members-metrics title="Sports Discipline" apiUrl="members/discipline"/>
                                        </div>
                                        <div class="col-lg-6">
                                            <members-metrics title="Member Types" apiUrl="members/type"/>
                                        </div>
                                    </div>
                                </div><!-- /.card-body -->
                            </div>
                        </section>
                        <section class="col-lg-5 connectedSortable">
                            <dist-map />
                            <MemberMap />
                            <member-type-graph />
                        </section>
                    </div>
                    <div class="row" v-if="subdomain == 'alliancefastpitch'">
                        <section class="col-lg-7 connectedSortable">
                            <active-members-graph />
                            <div class="card">
                                <div class="card-header bg-white">
                                    <h3 class="card-title">
                                    <i class="fas fa-chart-pie mr-1"></i>
                                        Registrations By Category
                                    </h3>
                                </div><!-- /.card-header -->
                                    
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <members-metrics title="Leagues" apiUrl="league/leagueData"/>
                                        </div>
                                        <div class="col-lg-6">
                                            <members-metrics title="Age Division" apiUrl="members/discipline"/>
                                        </div>
                                    </div>                             
                                </div><!-- /.card-body -->
                            </div>
                        </section>
                        <section class="col-lg-5 connectedSortable">
                            <dist-map />
                            <MemberMap />
                        </section>
                    </div>
                  <div class="row" v-if="subdomain == 'shooting'">
                    <section class="col-lg-7 connectedSortable">
                      <active-members-graph />
                      <!-- Custom tabs (Charts with tabs)-->
                      <div class="card">
                        <div class="card-header bg-white">
                          <h3 class="card-title">
                            <i class="fas fa-chart-pie mr-1"></i>
                            Registrations By Category
                          </h3>
                        </div><!-- /.card-header -->
                        <div class="card-body">
                          <div class="row">
                            <div class="col-lg-6">
                              <members-metrics title="Age" apiUrl="members/age"/>
                            </div>
                            <div class="col-lg-6">
                              <members-metrics title="Gender" apiUrl="members/gender"/>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6">
                              <members-metrics title="Type" apiUrl="members/type"/>
                            </div>
                          </div>
                        </div><!-- /.card-body -->
                      </div>
                    </section>
                    <section class="col-lg-5 connectedSortable">
                      <dist-map />
                      <MemberMap />
                      <member-type-graph />
                    </section>
                  </div>
                    <!-- /.row (main row) -->
                </div><!-- /.container-fluid -->
            </section>
            <!-- /.content -->
        </div>
    </div>
</template>

<script>
import StatBox from './StatBox';
import ActiveMembersGraph from './ActiveMembersGraph';
import DistMap from './DistMap';
import MemberMap from './MemberMap';
import MembersMetrics from './MembersMetrics.vue';
import MemberTypeGraph from './MemberTypeGraph.vue';


export default {
    name: 'dashboard',
    components: { MemberMap,StatBox, ActiveMembersGraph, DistMap, MembersMetrics, MemberTypeGraph},
}
</script>
