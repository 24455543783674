<template>
    <!-- solid sales graph -->
    <div class="card bg-gradient-primary">
        <div class="card-header border-0">
            <h3 class="card-title">
            <i class="fas fa-th mr-1"></i>
            Member Types
            </h3>

            <div class="card-tools">
            <button type="button" class="btn bg-primary btn-sm" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
            </button>
            </div>
        </div>
        <div class="card-body" style="position: relative; height: 450px;">
            <div class="fa-3x" style="position:absolute;left:45%;top:40%" v-if="!loaded">
                <i class="fas fa-spinner fa-pulse"></i>
            </div>
            <bar-chart :chartdata="chartData" :options="chartOptions" v-if="loaded"/> 
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import BarChart from './BarChart';
let cachedData = {};


export default {
    name: 'BarChartContainer',
    components: { BarChart },  
    data() {
        return {
            loaded: false,
            chartData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: [],
                        data: []
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    text: 'Pareto',
                    display: false,
                    fontColor: '#EEF2FF'
                },
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            fontColor: '#EEF2FF',
                            fontSize: '14',
                            labelString: '# Members'
                        },
                        ticks: {
                            fontColor: '#FFF',
                            beginAtZero: true
                        },
                        gridLines: {
                            color: '#EEF2FF'
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            fontColor: '#FFF',
                        }
                    }]
                }
            },
        }
    },
    methods: {
        getChartData() {
            this.loaded = false;
            if(Object.keys(cachedData).length === 0){
                axios.get(process.env.VUE_APP_URL + 'api/dashboard/members/type')
                .then((response) => {
                    cachedData = response.data.chartData;
                    this.chartData = cachedData;
                    this.loaded = true;
                    })
                .catch(error => this.errors = error.response.data);

                return false;
            }
            this.chartData = cachedData;
            this.loaded = true;
        }
    },
    mounted() {
        this.getChartData();
    }
}
</script>

<style scoped>

</style>
